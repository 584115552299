@import url("../../../../style/index.less");

.creator-detail-collection-page-wrapper {
  min-height: 100dvh !important;
  padding: @creator-collection-detail-header-height 0 30px 12px;

  .collection-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    margin-top: -60px;

    .left-icon {
      margin-right: auto;
    }

    .title-container {
      flex: 1;
      text-align: center;
    }

    .right-icon {
      margin-left: auto;
    }
  }

  .files-row {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .file-item {
      flex: 0 0 auto;
      width: 150px;
      height: 180px;
      margin-right: 8px;
      position: relative;

      .file-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;

        .file-image {
          width: 100%;
          height: 100%;
          min-height: 12rem;
            min-width: 10rem;
          object-fit: cover;
        }

        .play-icon-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 24px;
          z-index: 1;
        }

        .private-icon-overlay {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 24px;
          z-index: 1;
        }
      }
    }
  }

  .collection-resume-padding {
    padding: 0 12px 0 0;

    .container {
      width: 100%;
      height: auto;
      border-radius: 15px;
      background: rgba(46, 46, 54, 0.3);

      &.stats-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 15px 25px;

        .collection-stats-col {
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-text {
            font-family: @primary-font-bold;
            font-size: 20px;
            color: #ffffff;
          }
          .subtitle-text {
            font-family: @primary-font-regular;
            font-size: 11px;
            color: #8f8f91;
            margin-top: 5px;
          }
        }
      }

      &.info-container {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;

        .collection-info-col {
          display: flex;
          flex-direction: column;

          .title-text {
            font-family: @primary-font-bold;
            font-size: 20px;
            line-height: 26px;
            color: #ffffff;
          }
          .description-text {
            font-family: @primary-font-Thin;
            font-size: 14px;
            color: #f7f7f7;
          }
        }
      }

      &.report-container {
        width: 100%;
        padding: 15px 20px;

        .report-text-container {
          width: auto;
          padding: 5px 10px;
          border-radius: 10px;
          background: #26272c;
          margin-bottom: 10px;

          .report-text {
            font-family: @primary-font-bold;
            font-size: 10px;
            color: #ffffff;
          }
        }

        .report-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 0;
        }
      }
    }
  }

  .list-row {
    padding: 0 12px 0 0;
    margin-top: 20px;

    .container {
      width: 100%;
      height: auto;
      border-radius: 15px;
      background: rgba(46, 46, 54, 0.3);
      padding: 15px 15px 0px 15px;
      max-height: 350px;
      height: 100%;
      overflow-y: scroll;

      .ptr {
        padding-bottom: 15px;
      }

      &::-webkit-scrollbar {
        display: none !important;
      }

      .daily-report-container {
        background-color: #26272c;
        width: auto;
        padding: 6px 15px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        .daily-report-text {
          font-family: @primary-font-bold;
          font-size: 11px;
          color: #ffffff;
          text-align: center;
        }
      }

      .column {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .next-page-loading {
        margin-top: 30px;
        margin: auto;
      }
    }
  }
}
